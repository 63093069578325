<script setup>
const props = defineProps({
  heading: String,
  isGrid: Boolean,
  people: Array,
});
const noumberOfItems = computed(() => props.people.length);
const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
const slideWidth = computed(() => {
  if (!isMobile.value) {
    return props.people.length <= 2 ? "var(--cols-6)" : "var(--cols-3)";
  }
  return "calc(var(--cols-4) - var(--grid-gap))";
});

const shuffledItems = ref(
  [...props.people].sort((a, b) => 0.5 - Math.random()),
);
</script>

<template>
  <section class="people-grid">
    <ui-bleed class="w-full">
      <ui-font-text
        type="h2"
        class="mb-10"
        :class="{ 'lg:ml-push-6': people.length === 1 }"
      >
        {{ heading }}
      </ui-font-text>
    </ui-bleed>
    <client-only>
      <ul
        v-if="isGrid"
        class="grid gap-[var(--grid-gap)] md:grid-cols-2"
        :class="{
          'px-bleed': !isMobile,
          'lg:grid-cols-1': people.length === 1,
          'lg:grid-cols-2': people.length === 2,
          'lg:grid-cols-4': people.length > 2,
        }"
      >
        <li v-for="item in shuffledItems" class="shrink-0">
          <ui-bleed v-if="isMobile">
            <component-element-people-card
              v-bind="item"
              class="w-[var(--cols-4)] transition-all"
            />
          </ui-bleed>
          <component-element-people-card
            v-else
            v-bind="item"
            class="transition-all"
            :small-card="!isMobile && people.length <= 2"
            :class="{
              'lg:ml-auto': people.length === 1,
              'w-[var(--cols-6)]': people.length <= 2,
              'w-[var(--cols-3)]': people.length > 2,
            }"
          />
        </li>
      </ul>
      <div v-else class="people-carousel">
        <component-element-embla-carousel
          :slide-width="slideWidth"
          :has-navigation="people.length > 4"
          type="case"
        >
          <component-element-people-card
            v-for="(item, index) in shuffledItems"
            :key="index"
            v-bind="item"
            :small-card="!isMobile && people.length <= 2"
            class="embla__slide h-auto min-w-0"
            :class="{
              'ml-[var(--grid-gap)]': index !== 0,
              'lg:ml-auto': people.length === 1,
              'w-[var(--cols-6)]': people.length <= 2,
              'w-[var(--cols-3)]': people.length > 2,
            }"
          />
        </component-element-embla-carousel>
      </div>
    </client-only>
  </section>
</template>
