<script setup>
const props = defineProps({
  media: Object,
  offsetRight: Boolean,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <section class="media">
    <ui-half-width
      :class="{
        'pr-[var(--push-1)]': offsetRight && !isMobile,
      }"
    >
      <component-common-media
        :media="media"
        class="aspect-square h-full w-full overflow-hidden md:aspect-[9/5]"
      />
    </ui-half-width>
  </section>
</template>
