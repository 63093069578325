<script setup>
const props = defineProps({
  teaserTitle: String,
  media: Object,
  teaserDescription: String,
  parent: String,
  postDate: String,
  slug: String,
  url: String,
  theme: String,
  bigCard: {
    type: Boolean,
    default: false,
  },
  isGrid: {
    type: Boolean,
    default: false,
  },
});
const currentTheme = ref(null);
const hoverTheme = ref(null);
onMounted(() => {
  const { getCurrentTheme, getCorrespondingCSS } = useTheme();
  currentTheme.value = getCurrentTheme();
  hoverTheme.value = getCorrespondingCSS(props.theme);
});

const currentHoverTheme = computed(() => {
  return hoverTheme.value ? `var(${hoverTheme.value})` : currentTheme.value;
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <article
    class="insight-card group relative px-bleed py-4 lg:py-bleed"
    :class="{
      'insight-card-grid': isGrid,
      'insight-card-carousel': !isGrid,
    }"
    :style="`--hover-theme: ${currentHoverTheme}`"
  >
    <div
      class="background-color absolute left-0 top-0 h-full w-full lg:group-hover:bg-[var(--hover-theme)] lg:group-hover:opacity-10"
    ></div>
    <nuxt-link
      :to="`${'/insights/' + slug}`"
      aria-label="Insight"
      class="relative grid h-full w-full gap-3"
      :class="{
        'lg:flex lg:flex-row': bigCard,
      }"
    >
      <div
        v-if="(!isMobile && !bigCard) || isMobile"
        class="breadcrumbs inline-flex flex-wrap"
      >
        <ui-font-text v-if="parent" type="xs"> Article </ui-font-text>
        <ui-font-text v-if="parent" type="xs" class="text-dark-grey-3">
          \{{ parent }}
        </ui-font-text>
      </div>
      <div
        class="grid gap-10"
        :class="{
          'lg:flex lg:flex-row lg:gap-[var(--grid-gap)]': bigCard,
        }"
      >
        <div
          class="aspect-square w-[calc(var(--cols-1)+var(--grid-bleed)*2)] overflow-clip lg:w-[var(--cols-1)]"
          :class="{
            'lg:w-cols-2': bigCard,
          }"
        >
          <component-common-media
            class="h-full w-full object-cover"
            v-if="media?.attributes"
            :media="media"
          ></component-common-media>
          <component-common-profile-placeholder v-else />
        </div>
        <div class="content-wrapper flex flex-col justify-between">
          <div
            v-if="!isMobile && bigCard"
            class="breadcrumbs inline-flex flex-wrap"
          >
            <ui-font-text v-if="parent" type="xs"> Article </ui-font-text>
            <ui-font-text v-if="parent" type="xs" class="text-dark-grey-3">
              \{{ parent }}
            </ui-font-text>
          </div>
          <div class="teaser grid gap-4">
            <ui-font-text v-if="teaserTitle" type="m">
              {{ teaserTitle }}
            </ui-font-text>
            <ui-font-text
              v-if="teaserDescription"
              type="s"
              class="line-clamp-2"
            >
              {{ teaserDescription }}
            </ui-font-text>
          </div>
        </div>
      </div>
    </nuxt-link>
  </article>
</template>

<style>
.insight-card {
  --line-opacity: 0.25;
  --line-height: calc(100% - 2 * var(--grid-bleed));
}
.insight-card:hover {
  --line-opacity: 1;
  --line-height: 100%;
}
.insight-card-grid::before {
  @media (max-width: 900px) {
    content: "";
    position: absolute;
    background-color: var(--hover-theme);
    opacity: var(--line-opacity);
    transition: all 0.15s ease-in-out;
    z-index: 1;
    right: 50%;
    transform: unset;
    bottom: 0;
    top: unset;
    width: calc(100% - 2 * var(--grid-bleed));
    transform: translate(50%, 0%);
    height: 1px;
  }
}
.insight-card-grid::after {
  @media (min-width: 900px) {
    z-index: 1;
    content: "";
    position: absolute;
    background-color: var(--hover-theme);
    opacity: var(--line-opacity);
    transition: all 0.15s ease-in-out;
    top: 50%;
    transform: translate(0%, -50%);
    right: 0;
    bottom: unset;
    width: 1px;
    height: var(--line-height);
  }
}

.insight-card-carousel::after {
  z-index: 1;
  content: "";
  position: absolute;
  background-color: var(--hover-theme);
  opacity: var(--line-opacity);
  transition: all 0.15s ease-in-out;
  top: 50%;
  transform: translate(0%, -50%);
  right: 0;
  bottom: unset;
  width: 1px;
  height: var(--line-height);
}
</style>
