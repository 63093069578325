<script setup>
const props = defineProps({
  type: String,
  data: Object,
  idx: Number,
  id: Number,
});

const components = {
  accordionList: resolveComponent("section-accordion-list"),
  caseCarousel: resolveComponent("section-case-carousel"),
  caseCard: resolveComponent("section-case-card"),
  contentSection: resolveComponent("section-content"),
  eventTeaser: resolveComponent("section-event-teaser"),
  hero: resolveComponent("section-hero"),
  highlightedText: resolveComponent("section-highlighted-text"),
  hubsList: resolveComponent("section-hubs-list"),
  jobList: resolveComponent("section-job-list"),
  keyNumbers: resolveComponent("section-key-numbers"),
  media: resolveComponent("section-media"),
  quote: resolveComponent("section-quote"),
  peopleGrid: resolveComponent("section-people-grid"),
  text: resolveComponent("section-text"),
  videoCarousel: resolveComponent("section-video-carousel"),

  //Insights
  insightsGrid: resolveComponent("section-insights-grid"),
  insightInsightMedia: resolveComponent("section-insight-media"),
  insightText: resolveComponent("section-insight-text"),
  insightTextWithBackground: resolveComponent(
    "section-insight-text-with-background",
  ),

  // Event Sections
  eventAccordionList: resolveComponent("section-event-accordion-list"),
  eventQuote: resolveComponent("section-event-quote"),
  eventText: resolveComponent("section-job-text"),

  // Job Sections
  jobAccordionList: resolveComponent("section-job-accordion-list"),
  jobQuote: resolveComponent("section-job-quote"),
  jobText: resolveComponent("section-job-text"),
};
</script>

<template>
  <div>
    <component-behavior-in-view :threshold="0" :once="true">
      <component
        class="section overflow-x-clip"
        :is="components[type]"
        v-bind="data"
      />
    </component-behavior-in-view>
  </div>
</template>

<style scoped>
.observer {
  position: relative;
}
.observer:first-child {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(5vh);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease,
    filter 0.3s ease;
}
.observer.is-seen:first-child {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}
</style>
