<script setup>
const props = defineProps({
  insights: Array,
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
const slideWidth = computed(() => {
  return !isMobile.value
    ? props.insights.length < 3
      ? "var(--push-6)"
      : "var(--cols-3)"
    : props.insights.length < 3
      ? "calc(100% - var(--grid-bleed) * 2)"
      : "calc(var(--cols-4) - var(--grid-gap))";
});
</script>

<template>
  <section class="insights-carousel">
    <component-element-embla-carousel
      :slide-width="slideWidth"
      :has-navigation="insights.length > 4"
      type="video"
      :class="{ 'big-cards': insights.length < 3 }"
    >
      <component-element-insight-card
        v-for="(item, index) in insights"
        :key="index"
        v-bind="item"
        class="embla__slide h-full min-w-0"
        :big-card="insights.length < 3"
        :is-grid="false"
        :class="{
          'ml-[var(--grid-gap)]': index !== 0 && insights.length > 2,
        }"
      />
    </component-element-embla-carousel>
  </section>
</template>

<style>
.insights-carousel .bleed {
  @media (max-width: 900px) {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
