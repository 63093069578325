<script setup>
const props = defineProps({
  insightData: Object,
  containerHeight: Number,
});

const scrollPercentage = ref(0);

const updateScrollPercentage = () => {
  const stickyElement = document.querySelector(".sticky-insight");
  const container = document.querySelector(".insight-sections-wrapper");

  if (!stickyElement || !container) return;

  const containerRect = container.getBoundingClientRect();
  const stickyRect = stickyElement.getBoundingClientRect();

  // Calculate how much the sticky element has moved within the container
  const totalScrollableHeight = containerRect.height - stickyRect.height; // Max scroll distance
  const scrolledAmount = containerRect.top * -1; // How much has been scrolled

  // Normalize the value to 0-100%
  const progress = Math.max(
    0,
    Math.min(1, scrolledAmount / totalScrollableHeight),
  );
  scrollPercentage.value = Math.round(progress * 100);
};

onMounted(() => {
  window.addEventListener("scroll", updateScrollPercentage);
  updateScrollPercentage();
});

onUnmounted(() => {
  window.removeEventListener("scroll", updateScrollPercentage);
});
</script>

<template>
  <section
    class="sticky-insight sticky top-20 mt-spacing-section grid w-[var(--cols-4)] gap-4 pb-spacing-section md:mt-spacing-section-desktop md:pb-spacing-section-desktop"
  >
    <div class="content relative grid h-full w-full gap-16 p-bleed">
      <ui-tag-corners :transparent="true" />
      <div class="header grid gap-4">
        <div class="top inline-flex justify-between">
          <div class="breadcrumbs inline-flex flex-wrap">
            <ui-font-text v-if="insightData.meta.parent" type="xs">
              Article
            </ui-font-text>
            <ui-font-text
              v-if="insightData.meta.parent"
              type="xs"
              class="text-dark-grey-3"
            >
              \{{ insightData.meta.parent }}
            </ui-font-text>
          </div>
          <div class="counter text-dark-grey-3">
            <ui-font-text type="xs" class="text-dark-grey-3">
              {{ scrollPercentage }}%
            </ui-font-text>
          </div>
        </div>
        <component-element-highlighted-text
          :highlighted-text="insightData.meta.teaserTitle"
          :has-color="false"
          type="l"
          :has-scramble-effect="true"
        />

        <ui-font-text
          v-if="insightData.meta.postDate"
          type="xs"
          class="opacity-50"
        >
          Published {{ formatDate(insightData.meta.postDate) }}
        </ui-font-text>
      </div>
      <div v-if="insightData?.authors?.length > 0" class="authors grid gap-6">
        <component-element-insight-people-card
          v-for="(item, index) in insightData.authors"
          :key="index"
          v-bind="item"
          :has-background="false"
        />
      </div>
    </div>
    <ui-call-to-action
      v-if="insightData.cta"
      :cta="insightData.cta"
      :has-background="true"
      :grey-background="true"
      :has-icon="true"
    />
  </section>
</template>
